<template>
  <v-app>
    <v-app-bar color="primary" dark app>
      <v-toolbar-title>最终幻想14交易板价格查询</v-toolbar-title>
    </v-app-bar>
    <v-main>
      <v-container>
        <v-row justify="center" class="mt-5">
          <v-col cols="12" md="6">
            <v-autocomplete
              ref="autocomplete"
              v-model="selectedItem"
              :items="filteredItems"
              :search-input.sync="search"
              label="输入道具名称"
              item-text="text"
              item-value="id"
              @update:search-input="fetchAndFilterItems"
              :loading="loadingItems"
              outlined
              clearable
              prepend-inner-icon="mdi-magnify"
            >
            <template v-slot:item="data">
              <v-list-item 
              @click="selectItem(data.item.id)">
                <v-list-item-avatar>
                  <img :src="getItemIcon(data.item.icon)" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn @click="fetchItemPrice" color="primary" :disabled="loadingPrice">搜索</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-progress-linear
              v-if="loadingPrice"
              indeterminate
              color="primary"
              class="mb-3"
            ></v-progress-linear>
          </v-col>
        </v-row>
        <v-row v-if="listings.length">
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="listings"
              :items-per-page="5"
              class="elevation-1"
            ></v-data-table>
          </v-col>
        </v-row>
        <v-alert v-if="listings.length === 0 && fetched" type="info">
          未找到相关信息。
        </v-alert>
      </v-container>
    </v-main>
    <!-- <v-footer app>
      <v-col class="text-center">
        &copy; 2024 Your Company
      </v-col>
    </v-footer> -->
  </v-app>
</template>

<script>
import Papa from 'papaparse';

export default {
  data() {
    return {
      listings: [],
      fetched: false,
      loadingItems: false,
      loadingPrice: false,
      headers: [
        { text: '单价', value: 'pricePerUnit' },
        { text: '数量', value: 'quantity' },
        { text: '服务器', value: 'worldName' },
        { text: '雇员', value: 'retainerName' },
        { text: '总价', value: 'total' },
      ],
      items: [],
      filteredItems: [],
      search: '',
      selectedItem: null,
    };
  },
  methods: {
    async fetchAndFilterItems(search) {
      this.loadingItems = true;
      if (!this.items.length) {
        await this.fetchItems();
      }
      this.filterItems(search);
      this.loadingItems = false;
    },
    fetchItems() {
      return fetch('https://www.wolfspice.cloudns.ch/Item.csv')
        .then(response => response.text())
        .then(csvText => {
          Papa.parse(csvText, {
            header: true,
            complete: (results) => {
              const rows = results.data.slice(120);
              console.log(rows);
              this.items = rows
                .filter(row => row[22] == 'False')
                .map((row) => ({
                  id: row.key,
                  icon: row[10], // 假设 row[0] 是物品 ID
                  text: row[9], // 假设 row[1] 是物品名称
                }));
            },
          });
        });
    },
    filterItems(search) {
      if (search) {
        const lowerCaseSearch = search.toLowerCase();
        this.filteredItems = this.items.filter(item =>
          item.text?.toLowerCase()?.includes(lowerCaseSearch)
        );
      } else {
        this.filteredItems = this.items;
      }
    },
    padLeft(str, targetLength, padChar) {
      while (str.length < targetLength) {
        str = padChar + str;
      }
      return str;
    },
    getItemIcon(iconId) {
      let folderId;
      if (iconId.length >= 6) {
        iconId = this.padLeft(iconId, 6, '0');
        folderId = iconId.slice(0, 3) + "000";
      } else {
        iconId = "0" + this.padLeft(iconId, 5, '0');
        folderId = "0" + iconId.slice(1, 3) + "000";
      }
      return `https://xivapi.com/i/${folderId}/${iconId}.png`;
    },
    selectItem(id) {
      this.selectedItem = id;
      this.$refs.autocomplete.blur();  // Or this.$refs.autocomplete.menu.isActive = false;
    },
    async fetchItemPrice() {
      this.loadingPrice = true;
      this.fetched = false;
      try {
        const response = await fetch(`https://www.wolfspice.cloudns.ch/?item=${encodeURIComponent(this.selectedItem)}`);
        const data = await response.json();
        this.listings = data.listings;
        this.fetched = true;
      } catch (error) {
        console.error('Error fetching item price:', error);
        this.fetched = true;
      }
      this.loadingPrice = false;
    },
  },
};
</script>

<style scoped>
/* .v-application {
  background: linear-gradient(to right, #2196F3, #21CBF3);
} */

.v-autocomplete .v-input__control .v-input__append-inner {
  background-color: #FFFFFF;
}

.v-btn {
  margin-top: 8px;
}
</style>
