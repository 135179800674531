<template>
      <!-- <TestDemo/> -->
      <HelloWorld/>
</template>

<script>
// import TestDemo from './components/TestDemo';
import HelloWorld from './components/HelloWorld';

export default {
  name: 'App',

  components: {
    // TestDemo,
    HelloWorld,
  },

  data: () => ({
    //
  }),
};
</script>
